<script>
    /* eslint-disable */

    import NumberFormatter from "./utility/number-formatter";
    import AdvancedSearch from "./utility/advanced-search";
    import multiselect from "vue-multiselect";
    import moment from "moment";
    import InputWithConfirmation from '@/components/utility/input-with-confirmation';
    import Axios from "axios";

    export default {
        name: 'block-rental-details',
        props: {
            propsHirerNames: Array,
            details: {
                type: Object,
                default: function () {
                    return {
                        rentalAllocationRequired: null,
                        rentalAgreementNumber:'',
                        hirerName: '',
                        hirerFrom: {},
                        rentalCompany: '',
                        hireStartDate: '',
                        hireEndDate: '',
                        countDaysOnHire: 0,
                        dailyCostOfHire: 0,
                        excessReduction: 0,
                        adminFee: 0,
                        recoveryFee: 0,
                        deliveryFee: 0,
                        pickUpFee: 0,
                        subtotal: 0,
                        totalDue: 0,
                        gst: 0,
                    };
                }
            },
            isAllDisable: {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
        },
        data: function () {
            return {};
        },
        methods: {
            generateNumberAcceptationText(val){
              return `Add <b>${val}</b> as new rental agreement nbr`
            },
            onSelectHirerName: function(val){
                //console.log('val',val);
                //this.details.hirerName = val;
            },
            changeDaysOnHire() {
                if (!this.details.hireStartDate || !this.details.hireEndDate) {
                    return;
                }
                let start = null,
                    end = null;
                try {
                    start = Date.parse(this.details.hireStartDate);
                    end = Date.parse(this.details.hireEndDate);
                } catch {
                    return;
                }
                if (!start || !end) {
                    return;
                }
                end += 1000; // hack to include end day to calculation
                let diff = end - start;
                if (diff < 0) {
                    return;
                }
                this.details.countDaysOnHire = Math.ceil(diff / 1000 / 3600 / 24);
            },
        },
        computed: {
            computedDaysOnHire: {
              get(){
                if (!this.details.hireStartDate) {
                    console.log('no hireStartDate')
                  return 0;
                }
                let start = 0,
                    end = 0;
                try {
                  start = Date.parse(this.details.hireStartDate);
                  if(this.details.hireEndDate){
                    end = Date.parse(this.details.hireEndDate);
                  } else {
                    end = Date.now();
                  }
                } catch(e) {
                    console.log('error.computedDaysOnHire',e)
                  return 0;
                }
                if (!start || !end) {
                  return 0;
                }
                end += 1000; // hack to include end day to calculation
                let diff = end - start;
                if (diff < 0) {
                    console.log('diff < 0',diff)
                  return 0;
                }
                let result = Math.ceil(diff / 1000 / 3600 / 24);
                  console.log('result.computedDaysOnHire',result)
                if(result != this.details.countDaysOnHire){
                  this.details.countDaysOnHire = result;
                }
                return result;
              },
              set(newVal){
                if (!this.details.hireStartDate) {
                  return false;
                }
                let start = null,
                    end = null;
                try {
                  start = new Date(this.details.hireStartDate);
                } catch {
                  return false;
                }
                console.log(start)
                end = start.setDate(start.getDate() + Math.ceil(newVal - 1));
                this.details.hireEndDate = moment(end).format('YYYY-MM-DD');
              }
            },
            computedHirerName: {
                get() {
                    if (!this.details.hirerName || this.details.hirerName == '') {
                        return '';
                    }
                    if (this.details.hirerFrom && this.details.hirerFrom.id >= 0) {
                        return this.details.hirerFrom;
                    }
                    let vm = this;
                    return _.chain(this.propsHirerNames).filter(function (o) {
                        return (vm.details.hirerName === o.name);
                    }).first().value();
                },
                set(obj) {
                    if (obj && obj.name) {
                        this.details.hirerName = obj.name;
                        this.details.hirerFrom = obj;
                    }
            }
            },
          subtotal(){
            let sum = (
                this.details.dailyCostOfHire * 1
                + this.details.excessReduction * 1
                + this.details.adminFee * 1
                + this.details.recoveryFee * 1
            ) * this.computedDaysOnHire;
              sum += this.details.deliveryFee * 1;
              sum += this.details.pickUpFee * 1;
            return sum;
          },
          gst(){
            return Math.round(this.subtotal * 10) / 100;
          },
          total(){
            let t =  this.subtotal + this.gst;
              if (this.details && this.details.hirerFrom) {
                  this.$emit('rentalTotalChanged', {total: t, objId: Number(this.details.hirerFrom.id)});
              }
            return t;
          },
        },
        watch: {},
        components: {
          multiselect,
          NumberFormatter,
          AdvancedSearch,
          InputWithConfirmation,
        },
    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Details</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Rental Allocation Required</label>
                <div class="col-sm-8 col-8">
                    <label class="form-check-inline radio">
                        <input v-model="details.rentalAllocationRequired" class="form-check-input" type="radio" value="true"
                               @click="(!!details.rentalAllocationRequired)?details.rentalAllocationRequired=null:null"
                               @change="details.rentalAllocationRequired=true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="details.rentalAllocationRequired" class="form-check-input" type="radio" value="false"
                               @click="(!details.rentalAllocationRequired)?details.rentalAllocationRequired=null:null"
                               @change="details.rentalAllocationRequired=false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                    </label>
                </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">Rental Agreement Nbr</label>
              <div class="col-sm-8 col-8">
                <input-with-confirmation
                    v-model="details.rentalAgreementNumber"
                    :accept-text="generateNumberAcceptationText"
                    maxlength="20"
                    :disabled="isAllDisable" />
              </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Hirer Name</label>
                <div class="col-sm-8 col-8">
                    <multiselect
                            v-model="computedHirerName"
                            :options="propsHirerNames"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select name"
                            @select="onSelectHirerName"
                            :disabled="isAllDisable"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Rental Company</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="details.rentalCompany" class="form-control" :disabled="isAllDisable">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Hire Start Date</label>
                <div class="col-sm-8 col-8">
                    <b-form-datepicker
                            @input="changeDaysOnHire"
                            v-model="details.hireStartDate"
                            class="mb-2 "
                            locale="en-GB"
                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                            :disabled="isAllDisable"
                    ></b-form-datepicker>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Hire End Date</label>
                <div class="col-sm-8 col-8">
                    <b-form-datepicker
                            @input="changeDaysOnHire"
                            v-model="details.hireEndDate"
                            today-button
                            reset-button
                            close-button
                            class="mb-2 "
                            locale="en-GB"
                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                            :disabled="isAllDisable"
                    ></b-form-datepicker>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Nbr Days On Hire</label>
                <div class="col-sm-8 col-8">
                    <input v-model="computedDaysOnHire" type="text" class="form-control numeric" :disabled="isAllDisable">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Daily Cost of Hire</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="details.dailyCostOfHire" class="form-control numeric" format="$0,0.00" :isDisabled="isAllDisable"></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Excess Reduction</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="details.excessReduction" class="form-control numeric" format="$0,0.00" :isDisabled="isAllDisable"></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Admin Fee</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="details.adminFee" class="form-control numeric" format="$0,0.00" :isDisabled="isAllDisable"></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Recovery Fee</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="details.recoveryFee" class="form-control numeric" format="$0,0.00" :isDisabled="isAllDisable"></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Delivery Fee</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="details.deliveryFee" class="form-control numeric" format="$0,0.00" :isDisabled="isAllDisable"></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Pick-Up Fee</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="details.pickUpFee" class="form-control numeric" format="$0,0.00" :isDisabled="isAllDisable"></number-formatter>
                </div>
            </div>
          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Subtotal</label>
            <div class="col-sm-8 col-8">
              <number-formatter v-model="subtotal" class="form-control numeric" format="$0.00" :isDisabled="isAllDisable" readonly></number-formatter>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">GST</label>
            <div class="col-sm-8 col-8">
              <number-formatter v-model="gst" class="form-control numeric" format="$0.00" :isDisabled="isAllDisable" readonly></number-formatter>
            </div>
          </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Total Due</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="total" class="form-control numeric" format="$0.00" :isDisabled="isAllDisable" readonly></number-formatter>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
